import { Component, Fragment } from 'react';
import '../Impory.css';
import Loader from "react-loader-spinner";
//import * as Constants from '../constant/constant';
//import { Nav, Badge } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'
class SocialImp extends Component {
	
	static obj;
	
	state = {
		isLoading: true,
		//error:'',
	}
	
	constructor(props){
		super(props);
		SocialImp.obj = this;
	}
	render(){
		var content = '';
		var centerContent = '';
		if(this.state.isLoading){
			centerContent = (
				<div className="col-8">
				<h1>Social</h1>
				<span>Prochainement disponible.</span>
				</div>
			);
		}else{
			centerContent = (
				<div className="col-8">
					<Loader
						type="Circles"
						color="#FF6D14"
						height={100}
						width={100}
					/>
				</div>
			);
		}

		content = (
			<div className="rowImp">
				<div className="col">
					
				</div>
				{centerContent}
				<div className="col">
					
				</div>
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		SocialImp.obj = undefined;
	}
}
export default SocialImp;