import * as Constants from '../constant/constant';

export async function apiConnection(data, state){
	var prenom = data.profileObj.givenName;
	var nom = data.profileObj.familyName;
	var mail = data.profileObj.email;
	var pseudo = data.profileObj.name;
	var uid = data.profileObj.googleId;
	var pic = data.profileObj.imageUrl;

	const headers = new Headers();
	headers.append('Content-type', 'application/json');
	var requestOut = {
		request:{
			id_version:Constants.numeroVersion,
			type_request:4,
			STRUC:'imp_user',
			param1:pseudo,
			param2:pic,
			param3:uid,
			param4:mail,
			param5:prenom,
			param6:nom,
			param8:data.tokenId,
		}
	}
	const options = {
		method : 'POST',
		headers,
		body: JSON.stringify(requestOut)
	};
	requestOut.request.name = data.name;
	console.log("Requête sortante : ", JSON.stringify(requestOut));
	const request = new Request(Constants.WS_REQ_MANAGER, options);
	await fetch(request)
		.then(response => {
			if (response.ok) {
				return response.json();
			}else {
				state.setState({error:'Anomalie inconnu',isLoading: false});
			}
		  })
		.then(data => state.setState({user:data,isLoading: true}))
		.catch(err => state.setState({error:err, isLoading: false }));;
	if(state.state.isLoading && (state.state.error === '' || state.state.error === undefined)){
		console.log('Data receive: ', JSON.stringify(state.state.user));
		state.props.hdlConnexionSuccess(state.state.user.imp_user[0]);
	}else{
		console.log('Request in error : ' + state.state.error);
	}
}

export async function apiConnectionImpory(data, state){
	console.log("DATA : " + JSON.stringify(data.profileObj));
	var prenom = data.profileObj.givenName;
	var nom = data.profileObj.familyName;
	var mail = data.profileObj.email;
	var pseudo = data.profileObj.name;
	var uid = data.profileObj.googleId;
	var pic = data.profileObj.imageUrl;

	state.setState({isLoading : true});
	const headers = new Headers();
	headers.append('Content-type', 'application/json');
	var requestOut = {
		request:{
			id_version:Constants.numeroVersion,
			type_request:2,
			STRUC:'imp_user',
			param1:pseudo,
			param2:pic,
			param3:uid,
			param4:mail,
			param5:prenom,
			param6:nom,
		}
	}
	const options = {
		method : 'POST',
		headers,
		body: JSON.stringify(requestOut)
	};
	requestOut.request.name = data.name;
	console.log("Requête sortante : ", JSON.stringify(requestOut));
	const request = new Request(Constants.WS_REQ_MANAGER, options);
	await fetch(request)
		.then(response => {
			if (response.ok) {
				return response.json();
			}else {
				state.setState({error:'Anomalie inconnu',isLoading: false});
			}
		  })
		.then(data => state.setState({user:data,isLoading: false}))
		.catch(err => state.setState({error:err, isLoading: false }));;
	if(!state.state.isLoading && state.state.error === ''){
		console.log('Data receive: ', JSON.stringify(state.state.user));
		state.props.hdlConnexionSuccess(state.state.user.imp_user[0]);
	}else{
		console.log('Request in error : ' + state.state.error);
	}
}