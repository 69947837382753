import React from 'react';
import '../Impory.css';
import Loader from "react-loader-spinner";
//import * as Constants from '../constant/constant';
import * as apiStandard from '../api/apiStandard';
import * as CstRequest from '../constant/constantRequest';
import { Form, ListGroup, Image, Badge } from 'react-bootstrap';
import Impory from '../Impory';
import * as ImporyFunction from '../logic/ImporyFunction';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'
class MessageImp extends React.Component {
	
	static obj;
	messagesEndRef = undefined;
	
	state = {
		isLoading: false,
		isLoadingMessage: false,
		//error:'',
		conversations: [],
		messages: [],
		messageTape: '',
		currentUser: {},
	}

	constructor(props){
		super(props);
		MessageImp.obj = this;
		this.refreshData();
		this.messagesEndRef = React.createRef();
	}

	refreshData = () => {
		apiStandard.sendRequest(CstRequest.CST_MESSAGE, CstRequest.REQUEST_GET_CONVERSATION, [], this.onConversationLoad, this.onConversationLoadError);
	}
	onConversationLoadError = (strucName, idRequest, data, reponse) => {
		this.setState({isLoading: true})
	}
	onConversationLoad = (strucName, idRequest, data, reponse) => {
		this.setState({isLoading: true, conversations : reponse})
		if(reponse.imp_message[0]!==undefined){
			this.hdlLoadConv(reponse.imp_message[0]);
		}
	}
	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			apiStandard.sendRequest(CstRequest.CST_ACTION, CstRequest.REQUEST_ADD_MESSAGE, 
				[this.state.currentUser.idU, this.state.messageTape, this.state.messageTape], this.onMessageSend, this.onMessageSendError);
			this.setState({messageTape: ''});
		}
	}
	onMessageSendError = (strucName, idRequest, data, reponse) => {
		this.setState({isLoadingMessage: false})
	}
	onMessageSend = (strucName, idRequest, data, reponse) => {
		apiStandard.sendRequest(CstRequest.CST_MESSAGE, CstRequest.REQUEST_GET_MESSAGE, [this.state.currentUser.idU], 
			this.onMessagesLoad, this.onMessagesLoadError);
	}
	handleTapeMessage = (e) => {
		this.setState({messageTape : e.target.value});
	}

	hdlLoadConv = (conv) => {
		this.setState({isLoadingMessage: false, currentUser : conv})
		apiStandard.sendRequest(CstRequest.CST_MESSAGE, CstRequest.REQUEST_GET_MESSAGE, [conv.idU], this.onMessagesLoad, this.onMessagesLoadError);
	}
	onMessagesLoadError = (strucName, idRequest, data, reponse) => {
		this.setState({isLoadingMessage: true})
	}
	onMessagesLoad = (strucName, idRequest, data, reponse) => {
		this.setState({isLoadingMessage: true, messages : reponse})
	}

	componentDidUpdate () {
		this.scrollToBottom()
	}
	scrollToBottom = () => {
		if(this.messagesEndRef!==undefined){
			if(this.messagesEndRef.current!==null && this.messagesEndRef.current!==undefined){
				console.log("Scroo to : " + this.messagesEndRef.current);
				this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
			}else{
				console.log("Impossible de scoll : " + this.messagesEndRef.current);
			}
		}
	}

	render(){
		var content = '';
		var centerContent = '';
		var messagesContent = '';
		var keyboardContent = '';
		if(this.state.isLoadingMessage){
			messagesContent = (
				<div className="messageDetContContainer">
					{
						this.state.messages.imp_message.map((messages, index) =>{
							var content = '';
							var messageDecode = '';
							messageDecode=messages.message.replaceAll('&','\\');
							messageDecode=messageDecode.normalize();
							messageDecode = ImporyFunction.convertUnicode(messageDecode);


							if(messages.idUser !== Impory.obj.state.userConnected.id){
								content =(
									<div>
										<ListGroup.Item className="col-5 rowImp offset-7 contentMarger messagePreviewContainer">
											<div className="col-10">
												{messageDecode}
											</div>
											<div className="col-2">
												<Image src={Impory.obj.state.userConnected.url} 
													roundedCircle className="messageProfilImageContainer"/>
											</div>
										</ListGroup.Item>
									</div>
								)
							}else{
								content =(
									<div>
										<ListGroup.Item className="col-5 rowImp contentMarger messagePreviewContainer">
											<div className="col-2">
												<Image src={messages.pf} roundedCircle className="messageProfilImageContainer"/>
											</div>
											<div className="col-10">
												{messageDecode}
											</div>
										</ListGroup.Item>
									</div>
								)
							}
							return content;
						})
					}
					<div ref={this.messagesEndRef}></div>
				</div>
			);
			keyboardContent = (
				<div className="messageDetKeyboardContainer">
					<Form>
						<Form.Group>
							<Form.Control type="text" placeholder="Message à envoyer..." 
								value={this.state.messageTape} onKeyDown={this.handleKeyDown}
								onChange={this.handleTapeMessage}/>
						</Form.Group>
					</Form>
				</div>
			);
		}else{
			messagesContent = (
				<div className="messageDetContContainer">
					<Loader
						type="Circles"
						color="#FF6D14"
						height={100}
						width={100}
					/>
					<div ref={this.messagesEndRef}></div>
				</div>
			);
		}

		if(this.state.isLoading){
			centerContent = (
				<div className="col-md-11 col-12 contentMarger">
					<div className="col-12 rowImp">
						<div className="col-md-3 col-12 rowImp messageSmallContainer contentMarger">
						<div class="col-12 alert alert-info align-middle" role="alert">
							Contacts
						</div>
						{
								this.state.conversations.imp_message.map((convs, index) =>{
									var messageDecode = '';
									messageDecode=convs.message.replaceAll('&','\\');
									messageDecode=messageDecode.normalize();
									messageDecode = ImporyFunction.convertUnicode(messageDecode);
									
									return(
									<div className="col-4 rowVCenter rowHCenter" onClick={() => {this.hdlLoadConv(convs)}}>
										<Image src={convs.pf} roundedCircle className="messageProfilSmallImageContainer"/>
										<h6>{convs.pc} <Badge pill variant="danger">0</Badge>
										</h6>
									</div>)
								})
						}
						</div>
						<div className="col-md-9 col-12 messageDetContainer">
							<div class="col-12 alert alert-info align-middle" role="alert">
								Conversation avec {this.state.currentUser.pc}
							</div>
							{messagesContent}
							{keyboardContent}
						</div>
					</div>
				</div>
			);
		}else{
			centerContent = (
				<div className="col-8">
					<Loader
						type="Circles"
						color="#FF6D14"
						height={100}
						width={100}
					/>
				</div>
			);
		}

		content = (
			<div className="rowImp">
				<div className="col">
					
				</div>
				{centerContent}
				<div className="col">
					
				</div>
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		MessageImp.obj = undefined;
	}
}
export default MessageImp;