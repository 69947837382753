import { Component, Fragment } from 'react';
import '../Impory.css';
import Loader from "react-loader-spinner";
import * as apiStandard from '../api/apiStandard';
import * as CstRequest from '../constant/constantRequest';
//import * as Constants from '../constant/constant';
import { Alert, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons'
class NotificationImp extends Component {
	
	static obj;
	
	state = {
		isLoading: false,
		notifs:[],
		//error:'',
	}
	
	constructor(props){
		super(props);
		NotificationImp.obj = this;
		this.refreshData();
	}

	refreshData = () => {
		apiStandard.sendRequest(CstRequest.CST_NOTIFICATION, CstRequest.REQUEST_GALERIE_GET_NOTIFICATION, [], this.onNotifLoad, this.onNotifLoadError);
	}
	onNotifLoadError = (strucName, idRequest, data, reponse) => {
		this.setState({isLoading: true})
	}
	onNotifLoad = (strucName, idRequest, data, reponse) => {
		this.setState({isLoading: true, notifs : reponse})
	}
	showNotif = (notif) => {
		if(notif.type === 1){

		}else if(notif.type === 2){
		
		}else if(notif.type === 3){

		}else if(notif.type === 4){

		}else if(notif.type === 5){

		}else if(notif.type === 6){

		}else if(notif.type === 7){

		}
	}

	render(){
		var content = '';
		var centerContent = '';
		if(this.state.isLoading){
			centerContent = (
				<div className="col-md-8 col-12">
					<h1>Notifications</h1>
					<div className="rowImp">
					{
						this.state.notifs.imp_notification.map((notifs, index) =>{
							var typeNotif = 'light';
							var typeButton = 'danger';
							if(notifs.vue === 1){
								typeNotif = 'secondary';
								typeButton = 'light';
							}
							
							return(
								<div className="col-md-6 col-12 notifItem">
									<Card bg={typeNotif} key={index}  text={typeNotif === 'light' ? 'dark' : 'white'}>
										<Card.Body>
											<Card.Title>{notifs.titre}</Card.Title>
											<Card.Text>{notifs.dateCreation}<br/>{notifs.message}</Card.Text>
											<Button variant={typeButton} onClick={() => {this.showNotif(notifs)}}><FontAwesomeIcon icon={faBell}/> Consulter</Button>
										</Card.Body>
									</Card>
								</div>
							)})
					}
					</div>
				</div>
			);
		}else{
			centerContent = (
				<div className="col-8">
					<Loader
						type="Circles"
						color="#FF6D14"
						height={100}
						width={100}
					/>
				</div>
			);
		}

		content = (
			<div className="rowImp">
				<div className="col">
					
				</div>
				{centerContent}
				<div className="col">
					
				</div>
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		NotificationImp.obj = undefined;
	}
}
export default NotificationImp;