// Version
export const numeroVersion = 1002;
export const nameVersion ='Andrew';

// Pages
export const PAGE_ACCEUIL = 1;
export const PAGE_ALBUM = 2;
export const PAGE_PICTURES = 3;
export const PAGE_UPLOAD = 4;
export const PAGE_MESSAGE = 5;
export const PAGE_SOCIAL = 6;
export const PAGE_COMMANDE = 7;
export const PAGE_NOTIFICATION = 8;

// API
export const WS_REQ_MANAGER = 'https://www.impory.com/webservice_manager.php';
export const WS_PIC_MANAGER = 'https://www.impory.com/pictureManager.php';
export const WS_VIDEO 		= 'https://www.impory.com/';
export const WS_PIC_UPLOAD 	= 'https://www.impory.com/uploaderPicReact.php';

// ====== Text =======
export const T_APP_NAME = 'Impory';

// Menu
export const T_M_ACCEUIL = 'Acceuil';
export const T_M_CONNEXION = 'Connexion';
export const T_M_GALERIES = 'Mes Galeries';
export const T_M_MESSAGES = 'Messages';
export const T_M_SOCIAL = 'Social';
export const T_M_COMMANDES = 'Commandes';
export const T_M_NOTIFICATIONS = 'Notifications';

// Boutons
export const T_B_GOOGLE_CONNEXION = 'Connexion';

// Footer
export const T_F_PROPOS = 'A propos';
export const T_F_NORME_CONFIDENTIALITE = 'Norme de Confidentialité';
export const T_F_COND_UTILISATION = "Condition d'utilisation";
export const T_F_DROIT_RESERVE = "Tout droits réservés Impory - 2021";